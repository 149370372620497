<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <span
    v-if="tableItem.keys.length > 1"
    id="iiotFixCaseButton"
    ref="iiotLabelMergefixCaseModal"
    class="flat-button ma-0 cursor-pointer"
    @click="fixCaseModal()"
    @click.prevent.stop
  >
    {{ $t('labels.merge.fixCase') }}
  </span>
</template>

<script>
export default {
  props: {
    tableItem: {
      type: Object,
      default: () => ({
        keys: [],
      }),
    },
  },
  methods: {
    fixCaseModal() {
      this.$store.dispatch('labels/select_label_group', this.tableItem);
      this.$store.dispatch('labels/show_fix_case_dialog', true);
    },
  },
};
</script>
